import React from "react";

function AboutUs() {
  return (
    <div>
      {/* Header Start */}
      <div
        style={{
          background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="container-fluid bg-primary py-5 mb-5 page-header"
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Internshipwala: Empowering the Next Generation of Innovators
              </h1>
              <p className="mb-4">
                At Internshipwala, we are reimagining the way students prepare
                for their careers. As a forward-thinking technology and research
                center, we are dedicated to equipping students with the skills,
                knowledge, and practical exposure they need to succeed in the
                modern workforce.
              </p>
              <p className="mb-4">
                Our mission is simple: to help students discover their passion,
                develop relevant skills, and gain the hands-on experience that
                will set them apart in their careers. Imagine a world filled
                with opportunities where you can explore your interests, gain
                real-world expertise, and emerge as a confident, job-ready
                professional.<br/>
                 With Internshipwala, you don’t just graduate with
                a degree—you graduate with the confidence, competence, and
                readiness to thrive in any field. Join us and prepare to stake
                your claim in a future full of possibilities.
              </p>

              <h3 className="mb-4">Our Vision</h3>
              <p className="mb-4">
                At InternshipWala, we envision a world where learning is
                accessible to everyone, regardless of their location,
                background, or circumstances. We strive to break barriers and
                make education a transformative and inclusive experience for
                all.
              </p>

              <p className="mt-5 mb-4">
                Thank you for being a part of InternshipWala. Together, let's
                embark on a journey of lifelong learning and growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
